import { usePathname, useRouter } from 'next/navigation'
import { useCallback, useEffect, useState } from 'react'

const getToken = () =>
  typeof window !== 'undefined' ? localStorage.getItem('token') || '' : ''

const unsetToken = () =>
  typeof window !== 'undefined' ? localStorage.removeItem('token') : ''

const useAuth = (shouldRedirect: boolean = true) => {
  const [token, setToken] = useState<string>(getToken())
  const router = useRouter()
  const pathname = usePathname()

  useEffect(() => {
    if (!token && shouldRedirect && pathname !== '/') {
      router.push('/')
    }
  }, [router, token, shouldRedirect])

  const signOut = useCallback(() => {
    setToken('')
    unsetToken()
    window.location.href = '/'
  }, [])

  return {
    token,
    signOut,
  }
}

export { useAuth }
