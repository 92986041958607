import * as endpoints from '../api'
import ky from 'ky'
import { useAuth } from '../useAuth'
import { Activity } from '../../types'
import { useQuery } from '@tanstack/react-query'
import { formatISO, parseJSON } from 'date-fns'

type ActivityResponse = {
  average_heartrate: number | null
  average_pace: number
  distance: number
  city: string | null
  state: string | null
  time: number
  name: string
  route_svg: string
  start_date: string
}

type QueryParams = {
  before?: string
  after?: string
}

const fetchActivities = async (
  token: string,
  before?: Date,
  after?: Date,
): Promise<Activity[]> => {
  let params: QueryParams = {}

  if (before) {
    params.before = formatISO(before)
  }

  if (after) {
    params.after = formatISO(after)
  }

  const result = (await ky
    .get(endpoints.ACTIVITIES, {
      headers: { Authorization: `Token ${token} ` },
      searchParams: params,
    })
    .json()) as ActivityResponse[]
  return result.map((p) => {
    return {
      ...p,
      start_date: parseJSON(p.start_date),
    }
  }) as Activity[]
}

const useActivities = (before?: Date, after?: Date) => {
  const { token, signOut } = useAuth()

  return useQuery(
    ['activities', before, after],
    async () => {
      try {
        return await fetchActivities(token, before, after)
      } catch (e: any) {
        if (e.response.status === 401) {
          signOut()
        }
      }
    },
    {
      enabled: !!token,
    },
  )
}

export { useActivities, fetchActivities }
