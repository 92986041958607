import { Units } from '../../types'

export function finishTimeFromPace(secondsPerKm: number): number {
  return secondsPerKm * 42.195
}

export function formattedToPace(formatted: string): number {
  let [hours, minutes, seconds] = formatted.split(':')
  const totalSeconds =
    parseInt(hours) * 3600 + parseInt(minutes) * 60 + parseInt(seconds)
  return totalSeconds / 42.195
}

export function timeComponents(totalSeconds: number): [number, number, number] {
  let hours = Math.floor(totalSeconds / 3600)
  let minutes = Math.floor((totalSeconds - hours * 3600) / 60)
  let seconds = totalSeconds - hours * 3600 - minutes * 60
  return [hours, minutes, seconds]
}

export function formatTime(totalSeconds: number): string {
  let [hours, minutes, seconds] = timeComponents(totalSeconds)
  let secondsStr = Math.floor(seconds).toString()

  if (seconds < 10) {
    secondsStr = '0' + secondsStr
  }

  let minutesStr = minutes.toString()
  if (hours) {
    if (minutes < 10) {
      minutesStr = '0' + minutes
    }
    return hours.toString() + ':' + minutesStr + ':' + secondsStr
  }

  return minutesStr + ':' + secondsStr
}

export function formatTimeDiff(diffSeconds: number): string {
  let negative = false
  if (diffSeconds < 0) {
    negative = true
  }

  let timeString = formatTime(Math.abs(diffSeconds))
  if (negative) {
    return '-' + timeString
  } else if (diffSeconds === 0) {
    return timeString
  } else {
    return '+' + timeString
  }
}

export function secondsPerKmToSecondsPerMile(secondsPerKm: number): number {
  return secondsPerKm * 1.60934
}

export function distanceImperial(totalDistanceMetric: number): number {
  return totalDistanceMetric * 0.621371
}

export function formatDistance(distance: number, digits?: number) {
  if (digits) {
    return distance.toFixed(digits)
  } else {
    return Math.floor(distance)
  }
}

export function displayPace(secondsPerKm: number, units: Units) {
  let seconds = secondsPerKm
  let unit = 'km'
  if (units === 'imperial') {
    seconds = secondsPerKmToSecondsPerMile(secondsPerKm)
    unit = 'mi'
  }
  return `${formatTime(seconds)}/${unit}`
}

export function displayDistance(km: number, units: Units, digits?: number) {
  let total = km
  let unit = 'km'
  if (units === 'imperial') {
    total = distanceImperial(km)
    unit = 'mi'
  }
  return `${formatDistance(total, digits)} ${unit}`
}
