import { SYNC, SYNC_JOB, SYNC_RECENT } from '../api'
import { useAuth, useUser } from '..'
import { useCallback, useEffect, useState } from 'react'
import ky from 'ky'
import { queryClient } from '../../pages/_app'

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

const useSync = () => {
  const { data: user } = useUser()
  const { token, signOut } = useAuth()
  const [isSyncing, setIsSyncing] = useState(false)

  const pollSyncJob = useCallback(
    async (jobId: number) => {
      let finished = false
      let response
      while (!finished) {
        await sleep(1000)
        response = (await ky
          .get(`${SYNC_JOB}/${jobId}`, {
            headers: {
              Authorization: `Token ${token}`,
            },
          })
          .json()) as any
        finished = response.complete || response.failed
      }
      return response
    },
    [token],
  )

  const sync = useCallback(
    async (url: string) => {
      try {
        const response = await ky.post(url, {
          headers: { Authorization: `Token ${token} ` },
        })

        if (response.status !== 200) {
          signOut()
        }

        let data = (await response.json()) as any
        if (!data.complete || !data.failed) {
          data = await pollSyncJob(data.id)
        }

        if (data.failed) {
          signOut()
        }
      } catch (e) {
        signOut()
      }
    },
    [signOut, token, pollSyncJob],
  )

  const syncFull = useCallback(async () => {
    setIsSyncing(true)
    await sync(SYNC)
    queryClient.invalidateQueries(['prediction'])
    queryClient.invalidateQueries(['training'])
    queryClient.invalidateQueries(['activities'])
    setIsSyncing(false)
  }, [sync])

  const syncRecent = useCallback(async () => {
    setIsSyncing(true)
    await sync(SYNC_RECENT)
    queryClient.invalidateQueries(['prediction'])
    queryClient.invalidateQueries(['training'])
    queryClient.invalidateQueries(['activities'])
    setIsSyncing(false)
  }, [sync])

  useEffect(() => {
    const handleInProgressSync = async () => {
      try {
        if (user && user.sync_job_id) {
          setIsSyncing(true)
          const data = await pollSyncJob(user.sync_job_id)
          if (data.failed) {
            signOut()
          }
          queryClient.invalidateQueries(['user'])
          queryClient.invalidateQueries(['prediction'])
          queryClient.invalidateQueries(['training'])
          queryClient.invalidateQueries(['activities'])
        } else {
          setIsSyncing(false)
        }
      } catch (e) {
        signOut()
      }
    }
    handleInProgressSync()
  }, [user, user?.sync_job_id, pollSyncJob, signOut])

  return { syncFull, syncRecent, isSyncing }
}

export { useSync }
