import * as endpoints from '../api'
import ky from 'ky'
import { useAuth } from '../useAuth'
import {
  TrainingProperties,
  TrainingResponse,
  TrainingUpdateError,
  Units,
} from '../../types'
import { useQuery } from '@tanstack/react-query'
import { useUser } from '../useUser'

const fetchTraining = async (
  token: string,
): Promise<TrainingResponse | null> => {
  try {
    const response = await ky.get(endpoints.TRAINING, {
      timeout: false,
      headers: { Authorization: `Token ${token} ` },
    })
    const result = (await response.json()) as TrainingResponse
    return result
  } catch (e) {
    return null
  }
}

const updateTraining = async (
  token: string,
  targetDistance: number,
  units: Units,
) => {
  const result = (await ky
    .put(endpoints.TRAINING, {
      timeout: false,
      headers: { Authorization: `Token ${token} ` },
      throwHttpErrors: false,
      json: {
        target_distance: targetDistance,
        units,
      },
    })
    .json()) as TrainingResponse | TrainingUpdateError
  return result
}

const archiveTraining = async (token: string) => {
  const result = await ky.delete(endpoints.TRAINING, {
    timeout: false,
    headers: { Authorization: `Token ${token} ` },
    throwHttpErrors: false,
  })
  return result
}

const createTraining = async (token: string, props: TrainingProperties) => {
  const result = await ky
    .post(endpoints.TRAINING, {
      timeout: false,
      headers: { Authorization: `Token ${token} ` },
      json: {
        start: props.start,
        start_next_week: props.startNextWeek,
        target: props.targetDistance,
        timezone: props.timezone,
        units: props.units,
      },
    })
    .json() // as TrainingResponse | TrainingUpdateError
  return result
}

const useTraining = () => {
  const { token, signOut } = useAuth()
  const { data: user } = useUser()
  return useQuery(
    ['training'],
    async () => {
      try {
        return await fetchTraining(token)
      } catch (e: any) {
        if (e.response.status === 401) {
          signOut()
        }
      }
    },
    {
      enabled: !!token && !!user?.has_training_plan,
    },
  )
}

export { useTraining, fetchTraining, createTraining, archiveTraining }
