import * as endpoints from '../api'
import ky from 'ky'
import { useAuth } from '../useAuth'
import { Prediction } from '../../types'
import { useQuery } from '@tanstack/react-query'
import { formatISO, parseJSON } from 'date-fns'

type PredictionResponse = {
  date: string
  prediction: number
  distance: number
  pace: number
}

type QueryParams = {
  before?: string
  after?: string
}

const fetchPredictions = async (
  token: string,
  before?: Date,
  after?: Date,
): Promise<Prediction[]> => {
  let params: QueryParams = {}

  if (before) {
    params.before = formatISO(before)
  }

  if (after) {
    params.after = formatISO(after)
  }

  const result = (await ky
    .get(endpoints.PREDICTIONS, {
      headers: { Authorization: `Token ${token} ` },
      searchParams: params,
      timeout: 30000,
    })
    .json()) as PredictionResponse[]
  return result.map((p) => {
    return {
      ...p,
      date: parseJSON(p.date),
    }
  }) as Prediction[]
}

const usePredictions = (before?: Date, after?: Date) => {
  const { token, signOut } = useAuth()

  return useQuery(
    ['prediction', before, after],
    async () => {
      try {
        return await fetchPredictions(token, before, after)
      } catch (e: any) {
        if (e.response.status === 401) {
          signOut()
        }
      }
    },
    {
      enabled: !!token,
    },
  )
}

export { usePredictions, fetchPredictions }
