import * as endpoints from '../api'
import ky from 'ky'
import { useAuth } from '../useAuth'
import { GoalRace, GoalRaceError, Units, User } from '../../types'
import { useQuery } from '@tanstack/react-query'
import { usePostHog } from 'posthog-js/react'

const fetchUser = async (token: string): Promise<User> => {
  const result = await ky
    .get(endpoints.CURRENT_USER, {
      headers: { Authorization: `Token ${token} ` },
    })
    .json()
  return result as User
}

const putUser = async (
  token: string,
  email: string,
  units: Units,
): Promise<void> => {
  await ky.put(endpoints.CURRENT_USER, {
    headers: { Authorization: `Token ${token}` },
    json: {
      email,
      units,
    },
  })
}

const postGoalRace = async (
  token: string,
  name: string,
  date: string,
  goal_time: string,
): Promise<GoalRace> => {
  const response = (await ky
    .post(endpoints.GOAL_RACES, {
      headers: { Authorization: `Token ${token}` },
      json: {
        name,
        date,
        goal_time,
      },
      throwHttpErrors: false,
    })
    .json()) as GoalRace | GoalRaceError

  if ((response as GoalRaceError).error) {
    throw new Error((response as GoalRaceError).error)
  }

  return response as GoalRace
}

const deleteGoalRace = async (token: string): Promise<void> => {
  await ky.delete(endpoints.GOAL_RACES, {
    headers: { Authorization: `Token ${token}` },
    throwHttpErrors: false,
  })
}

const resendVerificationEmail = async (token: string): Promise<void> => {
  await ky.get(endpoints.RESEND_VERIFICATION_EMAIL, {
    headers: { Authorization: `Token ${token}` },
  })
}

const useUser = (shouldRedirect: boolean = true) => {
  const posthog = usePostHog()
  const { token, signOut } = useAuth(shouldRedirect)

  return useQuery(
    ['user'],
    async () => {
      try {
        const user = await fetchUser(token)

        posthog.identify(user.id.toString(), {
          email: user.email,
          name: `${user.first_name} ${user.last_name}`,
          email_verified: user.email_verified,
          email_verification_sent: user.email_verification_sent,
          first_name: user.first_name,
          last_name: user.last_name,
          goal_race: user.goal_race,
          has_pro_features: user.has_pro_features,
          has_stripe_customer: user.has_stripe_customer,
          has_training_plan: user.has_training_plan,
          units: user.units,
        })

        // TODO: hash identifier
        ;(<any>window).$chatwoot.setUser(user.id.toString(), {
          name: `${user.first_name} ${user.last_name}`,
          email: user.email,
        })

        return user
      } catch (e: any) {
        console.error(e)
        if (e.response.status === 401) {
          signOut()
        }
      }
    },
    {
      enabled: !!token,
    },
  )
}

export {
  useUser,
  fetchUser,
  putUser,
  resendVerificationEmail,
  postGoalRace,
  deleteGoalRace,
}
