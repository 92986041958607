import type { NextPage } from 'next'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useState, useRef } from 'react'
import { useAuth, useUser } from '../hooks'
import { Unsplash } from '../types'
import Footer from '../components/Marketing/Footer'
import Hero from '../components/Marketing/Hero'
import { API_HOST } from '../hooks/api'
import ky from 'ky'

interface Props {
  unsplash: Unsplash
}

const Home: NextPage<Props> = () => {
  const router = useRouter()
  const { signOut } = useAuth(false)
  const { data, isLoading, fetchStatus } = useUser(false)
  const [fetchingToken, setFetchingToken] = useState(false)
  const [fetchingUser, setFetchingUser] = useState(true)
  const tokenFetchedRef = useRef(false)

  const fetchToken = useCallback(
    async (code: string) => {
      if (tokenFetchedRef.current) return
      tokenFetchedRef.current = true

      try {
        const response = (await ky
          .post(`${API_HOST}/oauth/token`, {
            json: {
              code: code,
              provider: 'strava',
            },
          })
          .json()) as any
        if (response.error || !response.token) {
          signOut()
        } else {
          localStorage.setItem('token', response.token)
          if (response.created) {
            router.push('/sync')
          } else {
            router.push('/dashboard')
          }
        }
      } catch (e) {
        signOut()
      } finally {
        setFetchingToken(false)
      }
    },
    [router, signOut],
  )

  useEffect(() => {
    if (data) {
      router.push('/dashboard')
    }
  }, [data, router])

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const code = params.get('code')
    if (code && !fetchingToken && !tokenFetchedRef.current) {
      setFetchingToken(true)
      fetchToken(code)
    }
  }, [fetchToken, fetchingToken])

  useEffect(() => {
    setFetchingUser(isLoading && fetchStatus !== 'idle')
  }, [isLoading, fetchStatus])

  return (
    <div>
      <Head>
        <title>Metathon</title>
        <meta name="description" content="Metathon marathon race predictor" />
      </Head>

      <Hero isLoading={fetchingUser || fetchingToken} />
      <Footer />
    </div>
  )
}

export default Home
