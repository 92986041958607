import { useQuery } from '@tanstack/react-query'
import ky from 'ky'
import { TrainingParamsResponse, Units } from '../../types'
import * as endpoints from '../api'
import { useAuth } from '../useAuth'

const fetchTrainingParams = async (
  token: string,
  timezone: string,
  units: Units,
): Promise<TrainingParamsResponse | null> => {
  const response = await ky.get(endpoints.TRAINING_PARAMS, {
    timeout: false,
    headers: { Authorization: `Token ${token} ` },
    searchParams: {
      tz: timezone,
      units,
    },
  })

  const result = (await response.json()) as TrainingParamsResponse
  return result
}

const useTrainingParams = (timezone: string, units: Units) => {
  const { token, signOut } = useAuth()
  return useQuery(
    ['trainingParams', timezone, units],
    async () => {
      try {
        return await fetchTrainingParams(token, timezone, units)
      } catch (e: any) {
        if (e.response.status === 401) {
          signOut()
        }
      }
    },
    { enabled: !!token },
  )
}

export { useTrainingParams }
