import { UseQueryResult } from '@tanstack/react-query'
import { RefObject, useEffect, useState } from 'react'
import CountUp from 'react-countup'
import { formatTime } from '../../hooks/usePredictions/utils'
import { User } from '../../types'
import { StravaLoginButton } from './StravaLoginButton'

const Features = ({ isLoading }: { isLoading: boolean }) => {
  const [textColor, setTextColor] = useState('text-gray-900')

  useEffect(() => {
    setTextColor('text-green-500')
  }, [])

  return (
    <div id="features" className="relative overflow-hidden bg-white py-16">
      <div className="relative">
        <h2 className="text-center text-4xl font-bold leading-8 tracking-tight text-gray-900 mb-16">
          Features
        </h2>
      </div>
      <div className="relative">
        <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8">
          <div className="mx-auto max-w-xl px-4 sm:px-6 lg:mx-0 lg:max-w-none lg:py-16 lg:px-0">
            <div>
              <div className="mt-6">
                <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                  Marathon Predictor
                </h2>
                <p className="mt-4 text-lg text-gray-500">
                  Metathon can turn your Strava training data into an accurate
                  prediction for race day. Use the predictor as a tool for
                  training, race planning, and to ensure a personal best in your
                  next race.
                </p>
                <div className="mt-6">
                  <StravaLoginButton isLoading={isLoading} />
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center rounded-lg my-6 mx-4 border py-16 sm:m-0 sm:p-0 text-gray-900 shadow-xl">
            <CountUp
              start={4 * 3600 + 10 * 60 + 15} // 4:10:15
              end={3 * 3600 + 45 * 60} // 3:45:00
              duration={1}
              formattingFn={formatTime}
              delay={0}
            >
              {({ countUpRef }) => (
                <p
                  className={`text-7xl w-full text-center transform duration-1000 delay-750 ease-in ${textColor}`}
                  ref={countUpRef as RefObject<HTMLParagraphElement>}
                ></p>
              )}
            </CountUp>
          </div>
        </div>
      </div>
      <div className="mt-24">
        <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8">
          <div className="mx-auto max-w-xl px-4 sm:px-6 lg:col-start-2 lg:mx-0 lg:max-w-none lg:py-8 lg:px-0">
            <div>
              <div className="mt-6">
                <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                  Track Training Progress
                </h2>
                <p className="mt-4 text-lg text-gray-500">
                  Marathon training is hard to judge. How fit am I? What pace
                  should I target in my race? Most of the time, you can&apos;t
                  be sure. But with Metathon, you get daily feedback about your
                  fitness. Track your predicted finish time over the course of
                  your training program and show up to the start line confident
                  in your plan.
                </p>
                <div className="mt-6">
                  <StravaLoginButton isLoading={isLoading} />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:col-start-1 lg:mt-0">
            <div className="relative w-full h-0 pb-[75%] lg:pb-[56.25%] overflow-hidden rounded-xl shadow-xl ring-1 ring-black ring-opacity-5">
              <svg
                className="absolute top-0 left-0 w-full h-full"
                viewBox="0 0 520 270"
                preserveAspectRatio="xMidYMid meet"
                xmlns="http://www.w3.org/2000/svg"
              >
                {/* Background grid lines */}
                <line
                  x1="60"
                  y1="40"
                  x2="480"
                  y2="40"
                  stroke="#E5E7EB"
                  strokeWidth="1"
                />
                <line
                  x1="60"
                  y1="90"
                  x2="480"
                  y2="90"
                  stroke="#E5E7EB"
                  strokeWidth="1"
                />
                <line
                  x1="60"
                  y1="140"
                  x2="480"
                  y2="140"
                  stroke="#E5E7EB"
                  strokeWidth="1"
                />
                <line
                  x1="60"
                  y1="190"
                  x2="480"
                  y2="190"
                  stroke="#E5E7EB"
                  strokeWidth="1"
                />

                {/* Data points and lines */}
                <polyline
                  points="60,40 144,65 228,110 312,85 396,130 480,190"
                  fill="none"
                  stroke="#10B981"
                  strokeWidth="3"
                />
                <circle cx="60" cy="40" r="3" fill="#10B981" />
                <circle cx="144" cy="65" r="3" fill="#10B981" />
                <circle cx="228" cy="110" r="3" fill="#10B981" />
                <circle cx="312" cy="85" r="3" fill="#10B981" />
                <circle cx="396" cy="130" r="3" fill="#10B981" />
                <circle cx="480" cy="190" r="3" fill="#10B981" />

                {/* X-axis labels */}
                <text x="60" y="230" fill="#4B5563" fontSize="14">
                  Jan
                </text>
                <text x="144" y="230" fill="#4B5563" fontSize="14">
                  Mar
                </text>
                <text x="228" y="230" fill="#4B5563" fontSize="14">
                  May
                </text>
                <text x="312" y="230" fill="#4B5563" fontSize="14">
                  Jul
                </text>
                <text x="396" y="230" fill="#4B5563" fontSize="14">
                  Sep
                </text>
                <text x="480" y="230" fill="#4B5563" fontSize="14">
                  Nov
                </text>

                {/* Y-axis labels */}
                <text x="20" y="45" fill="#4B5563" fontSize="14">
                  4:30
                </text>
                <text x="20" y="95" fill="#4B5563" fontSize="14">
                  4:15
                </text>
                <text x="20" y="145" fill="#4B5563" fontSize="14">
                  4:00
                </text>
                <text x="20" y="195" fill="#4B5563" fontSize="14">
                  3:45
                </text>
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div className="relative mt-24">
        <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8">
          <div className="mx-auto max-w-xl px-4 sm:px-6 lg:mx-0 lg:max-w-none lg:py-16 lg:px-0">
            <div>
              <div className="mt-6">
                <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                  Improve on Past Performances
                </h2>
                <p className="mt-4 text-lg text-gray-500">
                  Metathon allows you to compare your current training with past
                  performances. Instead of hoping you are in personal best
                  shape, be sure.
                </p>
                <div className="mt-6">
                  <StravaLoginButton isLoading={isLoading} />
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center rounded-lg my-12 text-gray-900 text-xl md:text-3xl shadow-xl mx-4 sm:m-0 py-12 border">
            <table>
              <tbody>
                <tr>
                  <td className="text-gray-500 text-right px-4 py-2">
                    {new Date(
                      new Date().setFullYear(
                        new Date().getFullYear() - 2,
                        new Date().getMonth() + 2,
                      ),
                    ).toLocaleString('default', {
                      month: 'long',
                      year: 'numeric',
                    })}
                  </td>
                  <td className="px-4 py-2">3:59:05</td>
                </tr>
                <tr>
                  <td className="text-gray-500 text-right px-4 py-2">
                    {new Date(
                      new Date().setFullYear(
                        new Date().getFullYear() - 1,
                        new Date().getMonth() - 2,
                      ),
                    ).toLocaleString('default', {
                      month: 'long',
                      year: 'numeric',
                    })}
                  </td>
                  <td className="px-4 py-2">4:06:18</td>
                </tr>
                <tr>
                  <td className="text-gray-500 text-right px-4 py-2">
                    {new Date(
                      new Date().setFullYear(
                        new Date().getFullYear(),
                        new Date().getMonth() - 6,
                      ),
                    ).toLocaleString('default', {
                      month: 'long',
                      year: 'numeric',
                    })}
                  </td>
                  <td className="px-4 py-2">3:39:05</td>
                </tr>
                <tr>
                  <td className="text-gray-500 text-right px-4 py-2">
                    {new Date().toLocaleString('default', {
                      month: 'long',
                      year: 'numeric',
                    })}
                  </td>
                  <td className="px-4 py-2 text-green-500">3:24:08</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Features
