import Link from 'next/link'
import Image from 'next/image'

const footerNavigation = {
  social: [
    {
      name: 'Twitter',
      href: 'https://www.twitter.com/metathonapp',
      icon: (props: any) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
        </svg>
      ),
    },
  ],
}

const Footer = () => {
  return (
    <footer className="border border-top py-8" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="px-24 md:flex md:items-center md:justify-between">
        <div className="flex space-x-6 md:order-2">
          <div className="flex space-x-6">
            <Link href="/privacy.html" className="text-base text-gray-400">
              Privacy
            </Link>
          </div>
          <div className="flex space-x-6">
            {footerNavigation.social.map((item) => (
              <a key={item.name} href={item.href} className="text-gray-400">
                <span className="sr-only">{item.name}</span>
                <item.icon className="h-6 w-6" aria-hidden="true" />
              </a>
            ))}
          </div>
        </div>
        <p className="text-base text-gray-400 md:order-1 md:mt-0">
          &copy; {new Date().getFullYear()} Bolder Research, LLC. All rights
          reserved.
        </p>
        <Image
          src="/pbs.svg"
          alt="Powered by Strava"
          width={200}
          height={100}
        />
      </div>
    </footer>
  )
}

export default Footer
