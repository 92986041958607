import * as endpoints from '../api'
import ky from 'ky'
import { useQuery } from '@tanstack/react-query'
import { Unsplash } from '../../types'

const fetchUnsplash = async (): Promise<Unsplash> => {
  const result = await ky.get(endpoints.UNSPLASH).json()
  return result as Unsplash
}

const useUnsplash = () => {
  return useQuery(['unsplash'], () => fetchUnsplash(), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchInterval: false,
  })
}

export { useUnsplash, fetchUnsplash }
