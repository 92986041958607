export const API_HOST =
  process.env.NEXT_PUBLIC_API_HOST || 'https://api.metathon.com'

export const ACTIVITIES = `${API_HOST}/v2/activities`
export const CHECKOUT = `${API_HOST}/stripe/checkout`
export const CURRENT_USER = `${API_HOST}/v1/current_user`
export const GOAL_RACES = `${CURRENT_USER}/goal_races`
export const PORTAL = `${API_HOST}/stripe/portal`
export const PREDICTIONS = `${API_HOST}/v2/predictions`
export const RESEND_VERIFICATION_EMAIL = `${API_HOST}/v1/resend_verification_email`
export const SYNC = `${API_HOST}/v1/sync_full`
export const SYNC_JOB = `${API_HOST}/v1/sync_job`
export const SYNC_RECENT = `${API_HOST}/v1/sync_recent`
export const TRAINING = `${API_HOST}/v1/training`
export const TRAINING_PARAMS = `${API_HOST}/v1/training_params`
export const UNSPLASH = `${API_HOST}/v1/unsplash`
